import { makeStyles } from '@material-ui/core/styles';

const n = process.env;

export const postContentStyles = makeStyles(theme => ({
    root: {
        maxWidth: '830px',
        padding: 0,
        '& a': {
            textDecoration: 'underline !important',
            '&:hover': {
                color: n.REACT_APP_DEFAULT_TERTIARY_THEME_COLOR
            }
        },
        '& #tbw-disclaimer': {
            position: 'relative',
            top: '-10px',
            width: '100%',
            maxWidth: '750px',
            marginBottom: '10px',
            padding: '14px 16px 14px 60px',
            backgroundColor: '#ebefee',
            float: 'none',
            clear: 'both',
            '& > img': {
                position: 'absolute',
                top: '50%',
                left: '15px',
                float: 'left',
                maxWidth: '35px',
                margin: 0,
                transform: 'translateY(-50%)',
                '&.down': {
                    marginTop: '20px'
                }
            },
            '& > span': {
                float: 'left',
                width: '100%',
                paddingTop: '2px',
                textAlign: 'right',
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 500,
                color: '#9a9a9a',
                '& b': {
                    display: 'block',
                    textAlign: 'right',
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    color: '#9a9a9a'
                }
            },
            '& .clear': {
                clear: 'both'
            }
        },
        '& button.btn-default': {
            width: 'auto',
            maxWidth: '100%',
            padding: '20px',
            background: n.REACT_APP_DEFAULT_PRIMARY_THEME_COLOR,
            fontSize: '1rem',
            lineHeight: 1.6,
            color: theme.palette.primary.contrastText,
            cursor: 'pointer',
            [theme.breakpoints.up('md')]: {
                fontSize: '1.125rem',
                lineHeight: 1.8
            }
        },
        [theme.breakpoints.up('md')]: {
            padding: '15px'
        }
    },
    content: {
        fontSize: '1rem',
        lineHeight: 1.6,
        [theme.breakpoints.up('md')]: {
            fontSize: '1.125rem',
            lineHeight: 1.8
        },
        '& a': {
            '&:hover': {
                color: n.REACT_APP_DEFAULT_TERTIARY_THEME_COLOR
            }
        },
        '& p': {
            marginBottom: '1rem'
        },
        '& h3': {
            marginTop: '2rem',
            marginBottom: '1.625rem',
            fontSize: '1.4375rem',
            lineHeight: 1.3,
            [theme.breakpoints.up('md')]: {
                marginTop: '3rem'
            }
        },
        '& figure': {
            maxWidth: '100%',
            height: 'auto',
            margin: '15px 0px',
            '&.size-full': {
                marginBottom: 0
            }
        },
        '& figcaption': {
            position: 'relative',
            top: '-5px',
            padding: 0,
            border: 0,
            textAlign: 'left',
            fontSize: '9px',
            fontStyle: 'normal',
            boxShadow: 'none'
        },
        '& img': {
            maxWidth: '100%',
            height: 'auto',
            margin: '15px 0px',
            '&.size-full': {
                marginBottom: 0
            }
        },
        '& button.cta': {
            width: 'auto',
            maxWidth: '100%',
            padding: '10px',
            background: n.REACT_APP_DEFAULT_PRIMARY_THEME_COLOR,
            fontSize: '1rem',
            lineHeight: 1.6,
            color: theme.palette.primary.contrastText,
            cursor: 'pointer'
        },
        '& .card_cta a': {
            background: n.REACT_APP_DEFAULT_PRIMARY_THEME_COLOR,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                background: n.REACT_APP_DEFAULT_PRIMARY_THEME_COLOR,
                color: theme.palette.primary.contrastText
            }
        },
        '& .product-card': {
            background: '#fff',
            border: 'solid 0px',
            borderRadius: '5px',
            boxShadow: '0px 0px 10px #888888',
            margin: '50px 0px 40px 0px',
            padding: '20px',
            position: 'relative',
            '& .product-card-header': {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginTop: '-40px',
                marginBottom: '10px',
                marginLeft: '10px',
                marginRight: '10px',   
            },
            '& .product-card-numbers': {
                background: n.REACT_APP_DEFAULT_PRIMARY_THEME_COLOR,
                border: 'solid 0px',
                borderRadius: '50%',
                color: '#ffffff',
                left: '-46px',
                lineHeight: '50px',
                margin: 'initial',
                position: 'relative',
                textAlign: 'center',
                top: '-40px',
                width: '50px',
            },
            '& .product-card-cta-button': {
                background: n.REACT_APP_DEFAULT_PRIMARY_THEME_COLOR,
                borderColor: '#fb8c5b',
                border: 'solid 0px',
                borderRadius: '5px',
                color: '#ffffff!important',
                fontSize: '1.0rem',
                fontWeight: 'bold',
                padding: '9px 15px',
                margin: '25px',
                top: '50%',
                textAlign: 'center',
                textDecoration: 'none!important',
                textTransform: 'uppercase',
                width: 'max-content',
                whiteSpace: 'nowrap',
            },
            '& .product-card-cta-button:hover': {
                backgroundColor: '#e67b4d',
                color: '#ffffff!important',
            },
            '& .product-card-logo': {
                maxWidth: '30%',
            },
            '& .product-card h2': {
                color: '#000000',
                display: 'block',
                fontSize: '1.88rem',
                fontWeight: '500',
                lineHeight: '35px',
                marginBottom: '10px',
                marginTop: '15px',
                padding: '0px 80px 0px 80px',
                textAlign: 'center',
                textTransform: 'none',
            },
            '& .product-card h2::after': {
                display: 'none',
            },
            '& .product-card p': {
                marginLeft: '10px',
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: '40px',
                marginLeft: '3px',
                marginRight: '0px',
                marginTop: '40px',
                padding: '10px 10px 1px 10px',
                '& .product-card-header': {
                    marginLeft: '0px',
                    marginTop: '-23px',
                    padding: '4px',
                    width: '100%',
                },
                '& .product-card-numbers': {
                    borderRadius: '50%',
                    left: '-23px',
                    lineHeight: '30px',
                    top: '-23px',
                    width: '30px',
                },
                '& .product-card h2': {
                    display: 'block',
                    fontSize: '1.1rem',
                    lineHeight: '25px',
                    marginBottom: '5px',
                    marginTop: '-3px',
                    padding: '0px 0px 0px 0px',
                    textAlign: 'center',
                },
                '& .product-card-cta-button': {
                    fontSize: '0.55rem',
                    padding: '7px',
                    textAlign: 'center',
                },
                '& .product-card p': {
                    fontSize: '12px',
                    marginBottom: '0.5rem',
                    marginLeft: '4px',
                },
                '& .product-card li': {
                   fontSize: '12px',
                },
                '& .product-card ol': {
                    fontSize: '12px',
                },
                 '& .product-card ul': {
                    fontSize: '12px',
                },
                '& .product-card strong': {
                    fontSize: '12px',
                    marginTop: '5px',
                }
            }
        }
    },
    tooltipLink: {
        padding: 0,
        textDecoration: 'underline',
        color: n.REACT_APP_DEFAULT_BODY_TEXT_COLOR,
        cursor: 'pointer'
    },
    tooltip: {
        position: 'relative',
        opacity: 1,
        zIndex: 999,
        background: 'rgba(97, 97, 97, 1)',
        backgroundColor: 'rgba(97, 97, 97, 1) !important',
        '& .disclosure-lightbox > h2': {
            color: theme.palette.common.white
        },
        '& disclosure-lightbox p a:link': {
            color: n.REACT_APP_DEFAULT_TERTIARY_THEME_COLOR
        }
    },
    title: {
        fontSize: '1.75rem',
        lineHeight: 1.2,
        fontWeight: 700
    },
    info: {
        '& > span': {
            display: 'block',
            fontWeight: 600,
            [theme.breakpoints.up('sm')]: {
                display: 'inline-block'
            }
        }
    },
    date: {
        [theme.breakpoints.up('sm')]: {
            float: 'right'
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: '50px'
        }
    }
}));
